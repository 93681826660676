import ViewBox from "../../../ViewComponents/ViewBox";
import EditIcon from "../../../ViewComponents/EditIcon";
import {I18N} from "../../../i18n/i18n";
import React, {useCallback, useEffect, useState} from "react";
import {useLanguageState} from "../../../States/LanguageState";
import {useTheme} from "@mui/material";
import FormButton from "../../../ViewComponents/FormButton";
import LandingOperation from "../../Templates/LandingOperation";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import iconPublish from '../../../assets/iconPublish.svg';
import EmailOperation from "../../Templates/EmailOperation";
import {UserGroupsType, UserType} from "../../../Types/UserType";
import {DepartmentType} from "../../../Types/DepartmentTypes";
import {UserListsType} from "../../../Types/UserListsTypes";
import {UsersRepository} from "../../../Repositories/UsersRepository";
import {DepartmentsRepository} from "../../../Repositories/DepartmentsRepository";
import {ListsRepository} from "../../../Repositories/ListsRepository";
import {PhishingRepository} from "../../../Repositories/PhishingRepository";
import TargetGroupBar from "./Bars/TargetGroupBar";
import GeneralContentBar from "./Bars/GeneralContentBar";
import TemplatesBar from "./Bars/TemplatesBar";
import {useBreadCrumb} from "../../../States/BreadCrumbState";
import {useHistory} from "react-router-dom";
import paperPlane from '../../../assets/sharePlane.svg'
import languageExporter from "../../../Helpers/LanguageExporter";
const sweetAlert = withReactContent(Swal)

const userRespository = new UsersRepository();
const departmentsRepository = new DepartmentsRepository();
const userListRepository = new ListsRepository();
const phishingRepository = new PhishingRepository();

export default function EditCampaign(props: {
    editMode: any;
    closeEdit: () => void;
}){

    const [editMode, setEditMode] = useState(props.editMode);
    const [editGeneral, setEditGeneral] = useState(false);
    const [templatesOpen, setTemplatesOpen] = useState(false);
    const language = useLanguageState( state => state.language);
    const theme = useTheme();
    const [landingList, setLandingList] = useState([]);
    const [emailList, setEmailList] = useState([]);
    const breadCrumb = useBreadCrumb();
    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const [userList, setUserList] = useState<UserType[]>([]);
    const [lists, setLists] = useState<UserListsType[]>([]);
    const [departments, setDepartments] = useState<DepartmentType[]>([]);
    const [groups, setGroups] = useState<UserGroupsType[]>([]);

    const [isTargetGroupOpen, setIsTargetGroupOpen] = useState(false);

    const [stateOfLoadedElements, setStateOfLoadedElements] = useState({
        departments: [],
        userLists: [],
        groups: [],
        lists: []
    })



    const publishCampaignNow = useCallback(async () => {
        setLoading(true);
        await phishingRepository.pushCampaigns(editMode.id).then(async (response) => {
            setLoading(false);
            if(response){
                setLoading(false);
                await sweetAlert.fire({
                    confirmButtonColor: "#8CEEB6",
                    cancelButtonColor: "#F2F2F2",
                    reverseButtons: true,
                    customClass: {
                        cancelButton: "cancelCustom",
                        confirmButton: "confirmCustom"
                    },
                    title: I18N('success', language),
                    html: I18N('campaignExecutedSuccessfully', language),
                    icon: "success"
                });

                return;

            }else{
                setLoading(false);
                await sweetAlert.fire({
                    confirmButtonColor: "#8CEEB6",
                    cancelButtonColor: "#F2F2F2",
                    reverseButtons: true,
                    customClass: {
                        cancelButton: "cancelCustom",
                        confirmButton: "confirmCustom"
                    },
                    title: I18N('error', language),
                    html: I18N('unexpectedError', language),
                    icon: "error"
                });

                return;

            }


        }).catch(async (e) => {
            setLoading(false);
            await sweetAlert.fire({
                confirmButtonColor: "#8CEEB6",
                cancelButtonColor: "#F2F2F2",
                reverseButtons: true,
                customClass: {
                    cancelButton: "cancelCustom",
                    confirmButton: "confirmCustom"
                },
                title: I18N('error', language),
                html: I18N('unexpectedError', language),
                icon: "error"
            });
            return;
        })


    }, [editMode.id, language]);


    const loadSelectedItems = useCallback((departmentData, userListData, groupData, listsData) => {

        const depListWithLabel = [];
        const userListWithLabel = [];
        const groupListWithLabel = [];
        const listWithLabel = [];

        departmentData.forEach((item) => {
            if(editMode.departments.includes(item.ID)){
                depListWithLabel.push({label: item.name, value: item.ID});
            }
        });

        userListData.forEach((item) => {
            if(editMode.users.includes(item.id)){
                userListWithLabel.push({value: item.id, label: item.firstName + ' ' + item.lastName});
            }
        });


        groupData.forEach((item) => {
            if(editMode.groups.includes(item.id)){
                groupListWithLabel.push({label: item.name, value: item.id});
            }
        });


        listsData.forEach((item) => {

            if(editMode.lists.includes(item.id)){
                listWithLabel.push({label: item.name, value: item.id});
            }

        })

       setStateOfLoadedElements({departments: depListWithLabel, userLists: userListWithLabel, lists: listWithLabel, groups: groupListWithLabel});

    }, [editMode.departments, editMode.groups, editMode.lists, editMode.users]);


    const loadData = useCallback(async () => {
        setLoading(true);
        const uList =  userRespository.getAll();
        const permissionList =  userRespository.getGroups();
        const depList =  departmentsRepository.getAll();
        const listsData =  userListRepository.getAll();
        const emailListData = phishingRepository.getAllEmails();
        const landingListData = phishingRepository.getAllLandingPages();

        await Promise.all([uList,permissionList, depList, listsData, emailListData, landingListData]).then((data) => {
            console.log("sended requrest to load");
            loadSelectedItems(data[2], data[0], data[1], data[3]);
            setUserList(data[0] as UserType[]);
            setGroups(data[1]);
            setDepartments(data[2] as DepartmentType[]);
            setLists(data[3] as UserListsType[]);
            //@ts-ignore
            setLandingList(data[5]);
            //@ts-ignore
            setEmailList(data[4]);
            setLoading(false);


        }).catch((err) => {
            setLoading(false);
        });

        setLoading(false);

    }, [loadSelectedItems]);

    useEffect(() => {
        loadData();
    }, [editMode])


    useEffect(() => {
        breadCrumb.setChildren([]);
        breadCrumb.setBackButton(() => {
            if(props.closeEdit){
                props.closeEdit();
            }
        });
        breadCrumb.setTitle(props.editMode.designation);
        breadCrumb.setCrumb([
            {
                title: I18N('phishing', language),
                onClick: () => history.push('/phishing/overview')
            },{
                title: I18N('campaigns', language),
                onClick: () => () => {
                    if(props.closeEdit){
                        props.closeEdit();
                    }
                }
            }, ,{
                title: props.editMode.designation,
                onClick: () => history.push('/phishing/campaigns')
            }])

    }, []);


    const SmallBar = useCallback((name: string) => {
        return (
            <div style={{background: '#F2F2F2', paddingRight: 10, paddingLeft: 10, paddingBottom: 5, paddingTop: 5, fontSize: 10, textAlign: "center", width: "auto", borderRadius: 10, marginRight: 10}}>{name}</div>
        )
    }, []);

//{listConnection: undefined, designation: '', pushNow: false, executionDate: null, status: undefined, landingPage: undefined, email: undefined, person: undefined, userSelection: undefined, groupSelection: undefined, listSelection: undefined, departmentSelection: undefined}
    return (
        <>
            <GeneralContentBar isOpen={editGeneral} setIsOpen={setEditGeneral} content={editMode} setContent={setEditMode} lists={lists} userList={userList} />
            <TargetGroupBar isOpen={isTargetGroupOpen} setIsOpen={setIsTargetGroupOpen} content={editMode} setContent={setEditMode} groups={groups} departments={departments} lists={lists} userList={userList} selections={stateOfLoadedElements} />
            <TemplatesBar isOpen={templatesOpen} setIsOpen={setTemplatesOpen} content={editMode} setContent={setEditMode} emailList={emailList} landingList={landingList} />
            <div className="flex-row">
                <ViewBox width={37} rightComponent={(<EditIcon onClick={() => setEditGeneral(true)} />)} title={I18N('details', language)} style={{width: '100%', background: theme.palette.mode === 'dark' ? "#0A1929" : undefined}}>
                    <table style={{borderSpacing: 15}}>
                        <tr>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' :  "#3E4649", fontSize: 12}}>{I18N('title', language)}</td>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(62, 70, 73, 1)', fontSize: 14, fontWeight: "bold"}}>{editMode.designation}</td>
                        </tr>
                        <tr>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : "#3E4649", fontSize: 12}}>{I18N('executionDate', language)}</td>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : '#3E4649', fontSize: 14, fontWeight: "bold"}}>{editMode.execution_date}</td>
                        </tr>
                        <tr>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : "#3E4649", fontSize: 12}}>{I18N('accountable', language)}</td>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : '#3E4649', fontSize: 14, fontWeight: "bold"}}>{editMode.responsible_user_id}</td>
                        </tr>
                        <tr>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : "#3E4649", fontSize: 12}}>{I18N('responseList', language)}</td>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : '#3E4649', fontSize: 14, fontWeight: "bold"}}>{editMode.list_connection}</td>
                        </tr>

                        {editMode.videos && (<tr>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : "#3E4649", fontSize: 12}}>{I18N('learnContent', language)}</td>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : '#3E4649', fontSize: 14, fontWeight: "bold"}}>{languageExporter(editMode.videos, language, 'video_titles', 'title', 'title')}</td>
                        </tr>
                            )}
                    </table>
                </ViewBox>

                <ViewBox width={60} rightComponent={(<EditIcon onClick={() => setTemplatesOpen(true)} />)} title={I18N('templates', language)} style={{width: '100%', background: theme.palette.mode === 'dark' ? "#0A1929" : undefined}}>
                    <table style={{borderSpacing: 15}}>
                        <tr>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' :  "#3E4649", fontSize: 12}}>{I18N('email', language)}</td>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(62, 70, 73, 1)', fontSize: 14, fontWeight: "bold"}}>{editMode.phishing_emails.name}</td>
                        </tr>
                        <tr>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : "#3E4649", fontSize: 12}}>{I18N('landingpage', language)}</td>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : '#3E4649', fontSize: 14, fontWeight: "bold"}}>{editMode.phishing_landing_pages.designation}</td>
                        </tr>
                    </table>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "flex-end", bottom: 0, right: 0, marginTop: 20, paddingBottom: 0 }}>
                        <FormButton style={{width: "auto"}} text={I18N('showTemplate', language)} withLoadingInFullScreen isLoading={loading} icon={"eye"} color={"dark"} onClick={async () => {
                            await sweetAlert.fire({
                                width: '85%',
                                html: (<EmailOperation disableAll={true} editMode={editMode.phishing_emails}  />),
                            })
                        }}  />
                        <FormButton  style={{width: "auto", marginLeft: 10}} text={I18N('showLanding', language)} withLoadingInFullScreen isLoading={loading} icon={"eye"} color={"dark"} onClick={async () => {
                            await sweetAlert.fire({
                                showConfirmButton: false,
                                width: '75%',
                                html: (<LandingOperation disableAll={true} editMode={editMode.phishing_landing_pages} />),
                            })
                        }} />
                    </div>

                </ViewBox>


            </div>

            <div className="flex-row">

                <ViewBox width={37} rightComponent={(<EditIcon onClick={() => setIsTargetGroupOpen(true)} />)} title={I18N('targetGroup', language)} style={{width: '100%', background: theme.palette.mode === 'dark' ? "#0A1929" : undefined}}>
                    <table style={{borderSpacing: 15}}>
                        <tr>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' :  "#3E4649", fontSize: 12}}>{I18N('userList', language)}</td>
                            <td style={{display: "flex", justifyContent: "flex-start"}}>{stateOfLoadedElements.lists.map((i) =>  SmallBar(i.label))}</td>
                        </tr>
                        <tr>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : "#3E4649", fontSize: 12}}>{I18N('departments', language)}</td>
                            <td style={{display: "flex",  justifyContent: "flex-start"}}>{stateOfLoadedElements.departments.map((i) =>  SmallBar(i.label))}</td>
                        </tr>
                        <tr>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : "#3E4649", fontSize: 12}}>{I18N('permissions', language)}</td>
                            <td style={{display: "flex",  justifyContent: "flex-start"}}>{stateOfLoadedElements.groups.map((i) => SmallBar(i.label))}</td>
                        </tr>
                        <tr>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : "#3E4649", fontSize: 12}}>{I18N('users', language)}</td>
                            <td style={{display: "flex",  justifyContent: "flex-start"}}>{stateOfLoadedElements.userLists.map((i) =>  SmallBar(i.label))}</td>
                        </tr>
                    </table>
                </ViewBox>
                <div style={{opacity: editMode.execution_status ? 0.4 : 1, background: 'linear-gradient(180deg, #FFE16A 0%, #FAC804 100%)', width: '50rem', height: 207, borderRadius: 10, position: "relative", marginTop: 55}}>
                    <img style={{position: "absolute", top: -50, left: 20}} src={iconPublish} />
                    <div style={{marginTop: 48, padding: 30, maxWidth: '80%',height: 160, overflow: "hidden"}}>
                        <p style={{padding: 0, margin:0, fontWeight: "bold", fontSize: 24, color: "#000"}}>{I18N('startCampaignNow', language)}</p>
                        <p style={{padding: 0, margin:0, fontWeight: "normal", fontSize: 14, color: "#000"}}>{I18N('startCampaignText', language)}</p>
                    </div>

                    <div onClick={editMode.execution_status ? undefined : () => publishCampaignNow()} style={{position: "absolute", right: 15, bottom: 0, cursor: editMode.execution_status ? "disabled" : "pointer"}}>
                        <img src={paperPlane} />
                    </div>

                </div>

            </div>
        </>

    )
}