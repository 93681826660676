import {useQuery,} from "react-query";
import axios from "axios";

const getSubdomain = () => {
  const host = window.location.hostname; // z.B. "test.localhost"

  // Bei "localhost" als Hauptdomain, zusätzliche Behandlung
  if (host.endsWith("localhost")) {
    const parts = host.split(".");

    // Falls z. B. "test.localhost" → "test" zurückgeben
    if (parts.length > 1 && parts[parts.length - 1] === "localhost") {
      return parts.slice(0, parts.length - 1).join(".") || undefined;
    }

    return undefined; // Kein Subdomain-Teil
  }

  // Standard Subdomain-Erkennung für normale Domains
  const parts = host.split(".");
  if (parts.length > 2) {
    return parts.slice(0, parts.length - 2).join(".") || undefined;
  }

  return undefined; // Keine Subdomain
};

export const useLoadSubdomainData = () => {
  const subdomain = getSubdomain();
  return useQuery('loadSubdomainData', async() => {
    return axios({
      method:'GET',
      url: `/company/load-subdomain-data/${subdomain}`
    }).then(res => res.data)
  }, {retry: false})
}